import React from "react"
import { Link } from "gatsby"
import Layout from "../components/root/Layout"

export default function Home() {
  return (
    <Layout>
      <div className="container-lg py-8 md:py-16 lg:py-24 text-gray-800">
        <h1 className="text-5xl md:text-6xl leading-tight font-bold text-gray-800">
          Oh no,
        </h1>
        <h1 className="text-5xl md:text-6xl leading-tight font-bold text-brand-green mb-3">
          you're lost.
        </h1>
        <p className="mb-5 text-xl lg:w-1/2">
          Click your heels together three times and say 'There's no place like
          home', press the button below, and you'll be there.
        </p>
        <Link to="/">
          <div className="btn w-64 text-center">Take me home</div>
        </Link>
      </div>
    </Layout>
  )
}
